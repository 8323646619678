.content {
  margin-top: 60px;
  color: #fff;

  > .topBtns {
    display: flex;
    align-items: center;

    > span {
      cursor: pointer;
      margin-right: 40px;
      font-family: PlayfairDisplay-Medium;
      background-size: contain;
      background-repeat: no-repeat;
      font-size: 32px;
      min-width: 80px;
      text-align: center;

      &:last-child {
        margin-right: 0;
      }
    }

    > .active1 {
      background-image: url('/images/playground/hairstyle-ground-icon.webp');
      background-position: center center;
      font-family: CrimsonText-Italic;
    }

    > .active2 {
      // background-image: url('/images/playground/influencers-ground-icon.webp');
      background-position: center center;
      font-family: CrimsonText-Italic;
    }
  }

  .labelGroundContainer {
    width: 100%;
    margin-top: 40px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 15px;

    .moreLabelsList {
      flex-wrap: wrap;
    }

    .imgLabelsList {
      flex: 1;
      display: flex;
      align-items: center;
      gap: 15px;

      overflow-x: auto;

      /* 隐藏滚动条 */
      &::-webkit-scrollbar {
        display: none;
      }

      -ms-overflow-style: none;
      /* IE and Edge */
      scrollbar-width: none;
      /* Firefox */

      .imgLabelContainer {
        display: flex;
        align-items: center;
        gap: 5px;
        background: #2b2b2b;
        padding: 5px 10px 5px 5px;
        border-radius: 30px;
        white-space: nowrap;
        cursor: pointer;
        border: 1px solid #2b2b2b;

        .imgContainer {
          border-radius: 50%;
          width: 30px;
          height: 30px;
          object-fit: cover;
        }
      }

      .imgLabelActive {
        border: 1px solid #9873ff;
      }
    }

    .moreContainer {
      min-width: 100px;
      height: 40px;
      padding: 5px;
      border-radius: 30px;
      background: #2b2b2b;
      cursor: pointer;

      display: flex;
      align-items: center;
      justify-content: center;
      gap: 4px;
    }
  }

  .hairstyleGrid {
    margin-top: 36px;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 16px;
    margin-bottom: 40px;

    @media screen and (max-width: 900px) {
      grid-template-columns: repeat(4, 1fr);
    }

    @media screen and (max-width: 768px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media screen and (max-width: 576px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .hairstyleCard {
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

    .imageContainer {
      position: relative;
      aspect-ratio: 224/346;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      cursor: pointer;

      > img {
        border-radius: 12px;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .editBadge {
        background: linear-gradient(to top, rgba(0, 0, 0, 1), transparent);
        border-bottom-left-radius: 11px;
        border-bottom-right-radius: 11px;
        padding: 12px;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        font-size: 12px;

        display: inline-flex;
        align-items: center;
        gap: 4px;
      }

      .innerContent {
        z-index: 1;

        padding: 0 5px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 8px;
        text-align: center;
      }

      > .tryOnContainer {
        position: absolute;

        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-bottom: 20px;

        > span {
          background: var(--app-primary-color);
          color: #fff;
          padding: 5px;
          border-radius: 8px;
          min-width: 80%;
          display: inline-block;
          text-align: center;
          cursor: pointer;
        }

        display: none;
      }

      > .topRightContainer {
        position: absolute;
        top: 10px;
        right: 10px;

        width: 30px;
        height: 30px;
        border-radius: 5px;
        display: grid;
        place-items: center;
        background: #fff;
        color: var(--other-text-color);
        cursor: pointer;

        display: none;
      }

      &:hover {
        > .tryOnContainer {
          display: flex;
        }

        .topRightContainer {
          display: grid;
        }

        .editBadge {
          display: none;
        }
      }
    }

    .tags {
      padding: 12px 0;
      display: flex;
      flex-wrap: wrap;
      gap: 6px;

      .tag {
        color: #fff;
        padding: 2px 6px;
        border-radius: 5px;
        font-size: 12px;
        border: 1px solid red;
        cursor: pointer;
      }
    }
  }

  .loadMoreContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .loadMoreBtn {
      border-radius: 37px;
      border: 1px solid #ffffff;
      padding: 2px 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 2px;
      cursor: pointer;

      .arrowDown {
        font-size: 10px;
      }
    }
  }

  .moreButtonContainer {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  .moreButton {
    background-color: transparent;
    border: 1px solid #333;
    color: #fff;
    padding: 8px 40px;
    border-radius: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;

    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
    }

    .arrowDown {
      font-size: 10px;
    }
  }
}


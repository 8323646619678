.content {
  width: 30px;
  height: 30px;
  border-radius: 5px;
  background: #fff;
  color: #333;

  display: grid;
  place-items: center;
  cursor: pointer;
}

.listContainer {
  padding: 5px;

  > .itemContainer {
    display: flex;
    align-items: center;
    font-size: 14px;
    padding: 5px;
    cursor: pointer;
    border-radius: 5px;

    > span {
      margin-left: 5px;
    }

    &:hover {
      background: #999;
    }
  }
}

